import home from "./home.json";
import confirmDialog from "./confirm-dialog.json";
import boards from "./boards.json";
import auth from "./auth.json";
import profile from "./profile.json";
import error from "./error.json";
import offersCatalog from "./offersCatalog.json";
import statistic from "./statistic.json";
import offer from "./offer.json";
import streams from "./streams.json";

export default {
  home: { ...home },
  confirmDialog: { ...confirmDialog },
  boards: { ...boards },
  auth: { ...auth },
  profile: { ...profile },
  error: { ...error },
  offersCatalog: { ...offersCatalog },
  statistic: { ...statistic },
  offer: { ...offer},
  streams: { ...streams},
  menuLocal: {
    home: "Главная",
    boards: "Доски",
  },
  btn: {
    settings: "Настройки",
    integrations: "Интеграции",
    password_change: "Смена пароля",
    translate: "Язык",
    logout: "Выйти",
    add: "Добавить",
    delete: "Удалить",
    update: "Обновить",
    close: "Закрыть",
    save: "Сохранить",
    back: "Назад",
    apply: "Применить",
    confirm: "Подтвердить",
    edit: "Редактировать",
    all: "Все",
    archiveRestore: "Восстановить",
    addTime: "Добавить время",
    selectTime: "Выбрать Время",
    HintTestCases: "Тест кейсы",
    create: "Создать",
    remove_offer:"Удалить оффер",
    remove_article:"Удалить новость",
    cancel: "Отменить",
    add_a_picture: "Добавить картинку",
    delete_picture: "Удалить картинку",
    fullDescriptionCollapse: "Свернуть",
    fullDescriptionReadinfull:"Читать полностью",
    create_a_stream: "Создать поток",
    details:"Подробности",
    delete_user:"Удалить пользователя",
    add_an_order:"Добавить заказ",
    addNews:"Добавить новость",
    
  },
  settings: {
    name: "Название",
    country_code: "Код страны",
    actions: "Действия",
    price: "Цена",
    discount: "Скидка",
    phone_number: "Номер телефона",
    icon: "Иконка",
  },

    sideBar: {
      main: "Основное",
      additional: "Дополнительно",
      panel: "Панель",
      news: "Новости",
      orders: "Заказы",
      offersCatalog: "Каталог офферов",
      streams: "Потоки",
      statistic: "Статистика",
      transactions: "Транзакции",
      settings: "Настройки",
      users: "Пользователи",
      admin_panel: "Админ панель",
      statistic_seller: "Статистика (рекламодатель)",
      transactions_seller: "Транзакции (рекламодатель)",
    },
  
  table: {
    actions: "Действия",
    user_name: "Имя пользователя",
    email: "Email",
    phone_number: "Номер телефона",
    role: "Роль",
  },
  form: {
    advertorials: "Адверториалы",
    Userisundefined: "Поле не должно быть пустым",
    email: "E-mail",
    password: "Пароль",
    name: "Имя",
    surname: "Фамилия",
    phone_number: "Номер телефона",
    balance: "Баланс",
    search: "Поиск",
    

    title_setting: "Настройки",
    phone: "Номер телефона",
    Curpassword: "Текущий пароль",
    NewPass: "Новый пароль",
    Confirpassword: "Подтвердить пароль",
    ChangePhoto: "Изменить фото",
    Tasks: "Задачи",
    Profile: "Профиль",
    username: "Имя пользователя",
    nickname: "Имя пользователя",
    deluserboard: "Пользователь успешно удален",
    adduserboard: "Пользователь успешно добавлен",
    addSuccess: "Успешно добавлено",
    workloadCc: "Загруженность КЦ",


    // новые переводы
    deleteSuccess: "Успешно удалено",
    duplicateCategoryError:"Название категории уже занято.",
    duplicateGeoError:"Название геолокации уже занято.",
    title: "Название",
    description: "Описание",
    instock:"В наличии",
    dropprice: "Цена дроп:",
    country: "Страна",
    the_goal_is_Redemption: "Цель-выкуп",
    price_drop: "Цена дропа",
    retail_price: "Розничная цена",
    estimated_profit:"Ориентировочная прибыль",
    call_center:"Колцентр",
    works:"Работает 7 дней в неделю: 8:00-22:00 по Киеву (языки UA, RU)",
    code:"Код",
    geo:"Гео(ограничение)",
    additional: "Дополнительно",
    You_may_be_interested:"Вас может заинтересовать",
    discount:"Скидка",
    country_code:"Код страны",
    add_a_category:"Добавить категорию",
    add_location:"Добавить локацию",
    add_crm:"Добавить CRM",
    landings:"Лендинги",
    UAH:"грн",
    streams:"Потоки",
    role:"Роль",
    edit_user:"Редактировать пользователя",
    edit_crm:"Редактировать CRM",
    edit_article:"Редактировать новость",
    client_name:"Имя клиента",
    create_an_order:"Создать заказ",
    create_a_news:"Создать новость",
    edit_a_news:"Редактировать новость",
    processing_type:"Тип обработки",
    status:"Статус",
    statuses:"Статуси",
    number: "Номер",
    all: "Все",
    new: "Новые",
    not_processed: "Не обработано",
    missed_call: "Пропущенный звонок",
    in_progress: "В процессе",
    confirmed: "Подтверждено",
    rejected: "Отказано",
    shipped: "Ожидают выкупа",
    not_purchased: "Не выкуплено",
    purchased: "Выкуплено",

    salesStatistics:"Статистика продаж",
    trafficStatistics:"Статистика трафика",

    no_landings:"Лэндинги в данном потоке отсутствуют!",

    image:"Изображение",

    margin:"Маржа",
    important:"Важное",
    worldNews:"Новости мира",

    UTMTags:"UTM метки",
    AddLabels:"Доп. метки",

    GEO:"ГЕО",
    CATEGORIES:"КАТЕГОРИИ",

    characteristics:"Характеристики",
    offers:"Офферы",

    prices:'Цены',
    payment:'Выплата',
    deleteImgDialog: {
      DelImg: "Удалить картинку",
      Confirm: "Подтверждение удаления",
      Textconfirm: "Вы уверены, что хотите удалить данное изображение?",
      delete_dialog_title: "Удалить",
      delete_dialog_desc: "Вы действительно хотите это удалить?",
    },
    deleteConfirmDialog: {
      Confirm: "Подтверждение удаления",
      Textconfirm: "Вы действительно хотите это удалить?",
      delete_dialog_title: "Удалить",
    },    
    archiveDialog: {
      archiveTitle: "Архив",
      archive_nameTask: "Название задачи",
      archive_dataDelete: "Дата удаления"
    },
    errors: {
      SurnameRequired: "Фамилия является обязательным полем!",
      TimeRequired: "Время является обязательным полем!",
      PriceMoreThanRequired: "Цена должна быть больше, чем цена оффера",
      EmailRequired: "Email является обязательным полем!",
      EmailMustBeValid: "Email должен быть валидным!",
      EmailalreadyExists: "Такой email уже существует.",
      nicknamereg: "Никнейм обязательный!",
      PasswordRequired: "Пароль обязательный!",
      PasswordsDontMatch: "Пароли не совпадают!",
      PasswordMustBeLike:
        "Выберите более надежный пароль. Попробуйте комбинацию букв, цифр и символов. Пароль должен содержать как минимум одну заглавную букву. Минимальная длина пароля должна составлять 8 символов.",
     
      PhoneNumberMustBeLike: "Телефон должен содержать: +(380)-000-000-000",
      photoMaxSize: "Фото должно быть меньше 8 МБ!",
      ListAndNameRequired: "Список и название обязательны",
      
      Selectedimageerror: "Выбранный файл не является изображением",
      
      required: "Поле обязательно для заполнения!",
      ConfirmPassRequired:"Подтверждение пароля обязательно!",


      // новые
      loginError: "Ваши данные для входа неверны",
      registerUsername: "Имя пользователя уже занято.",
      CurrentPassRequired:"Пароль является обязательным полем!",
      PriceAboveDrop:"Цена должна быть выше, чем цена дроп",
      PhoneNumberRequired: "Номер телефона является обязательным полем!",
      geoRequired: "Гео является обязательным полем!",
      DiscountRequired: "Скидка является обязательным полем!",
      OfferRequired: "Офферы являются обязательным полем!",
      PasswordMustContainUppercase: "Пароль должен содержать заглавную букву!",
      PriceRequired: "Цена является обязательным полем!",
      PayoutRequired: "Виплата является обязательным полем!",
      PayoutModelRequired: "Тип виплати является обязательным полем!",
      NameRequired: "Имя является обязательным полем!",
      TitleRequired: "Название является обязательным полем!",
      MarginRequired: "Маржа является обязательным полем!",
      DescriptionRequired: "Описание является обязательным полем!",
      streamRequired:"Потоки являются обязательным полем!",
      CRMRequired:"CRM PRODUCT ID является обязательным полем!",
      DiscountCannotExceed99: "Скидка не может превышать 99",
      UrlRequired:"URL является обязательным полем!",
      inputTokenRequired:"Input Token является обязательным полем!",
      outputTokenRequired:"Output Token является обязательным полем!",
    },
    checklistDialog: {
      addTestCase: "Добавить тест-кейс",
      taskList: "Список задач",
    }, 
    noData: "Данных нет!",
    time: "Время",
    day_singular: "день",
    day_few: "дня",
    day_many:'дней',
  },
  headers: {
      date: "Дата",
      totalClicks: "Кликов",
      uniqueClicks: "Уников",
      cr: "CR %",
      approve: "Апрув %",
      purchased: "Выкуп %",
      epc: "EPC",
      expectedIncome: "Плановый доход, грн.",
      totalOrders: "Заказов всего",
      newCount: "Новый к-во",
      inProgressCount: "Обработка к-во",
      confirmedCount: "Подтв. к-во",
      rejectedCount: "Отм. к-во",
      shippedCount: "Отпр. к-во",
      notPurchasedCount: "Невыкуп. к-во",
      purchasedCount: "Выкуп. к-во",
      missedCallCount: "Недозв. к-во",
      newIncome: "Новый, грн.",
      inProgressIncome: "Обработка, грн.",
      confirmedIncome: "Подтв., грн.",
      rejectedIncome: "Отм., грн.",
      shippedIncome: "Отпр., грн.",
      notPurchasedIncome: "Невыкуп., грн.",
      purchasedIncome: "Выкуп., грн.",
      missedCallIncome: "Недозв., грн.",
      actualIncomeUAH: "Фактический доход, грн.",
      actualEPC: "EPC, факт",
      TotalNumberOfOrders: "Общее количество заказов",
      potentialIncome: "Потенциальный доход с общего количества заказов",
      actualIncome: "Фактический доход",
      id_transactions: "ID транзакций",
      id_order: "ID заказа",
      operations: "Операции (начисление/списание)",
      sum: "Сумма",
      balance_after_operation: "Баланс после операции",
      comment: "Коментарий",
      name: "Имя",
  },
  
    users: {
      successfully_deleted: "Пользователь успешно удален",
      name: "Имя"
    }
  
};
