<template>
  <v-navigation-drawer
      fixed
      v-model="miniMob"
      absolute
      :style="{ top: '56px', height: `calc(100vh - 56px)`, width: mini ? '237.5px' : '0px', 'z-index':100}"
      :class="{ fixed: fixed }"
      :permanent="$vuetify.breakpoint.mdAndUp"
      :mini-variant="!mini"
      :border="true"
  >
    <v-divider />
    <v-list nav dense >
      <v-list-item-group  active-class="active-blue--text" color="blue">
        <div v-for="(list, key) in listData" :key="key">
          <div v-if="list.isLabel && list.permission" class="menu-label">
            <span>{{ list.label }}</span>
          </div>
          <div v-else-if="'nested_data' in list">
            <v-list-group
                color="green"
                class="listGroupMenu"
                v-if="list.permission"
                :value="list.value"
                active-class="active-green--text"
                @click="navigateTo(list.navigate_name)"
            >
              <template v-slot:activator>
                <v-list-item-icon>
                  <v-icon>{{ list.activator_data.icon }}</v-icon>
                </v-list-item-icon>

                <v-list-item-title>{{ list.activator_data.title }}</v-list-item-title>
              </template>

              <div v-for="(nested_data, key) in list.nested_data" :key="key">
                <v-list-item
                    active-class="active-blue--text"
                    :to="$localize({ name: nested_data.navigate_name })"
                    v-if="nested_data.permission"
                >
                  <v-tooltip right>
                    <template v-slot:activator="{ on, attrs }">
                      <v-list-item-icon v-bind="attrs" v-on="on"
                      ><v-icon>{{ nested_data.icon }}</v-icon></v-list-item-icon
                      >
                    </template>
                    {{ nested_data.title }}
                  </v-tooltip>
                  <v-list-item-title>{{ nested_data.title }}</v-list-item-title>
                </v-list-item>
              </div>
            </v-list-group>
          </div>
          <div v-else>
            <v-list-item
                :active-class="$vuetify.theme.dark ? 'active-blue--textBlack' : 'active-blue--text'"
                :class="list.class"
                :to="$localize({ name: list.navigate_name, params: list.navigate_params })"
                v-if="list.permission"
                exact
            >
              <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                  <v-list-item-icon v-bind="attrs" v-on="on">
                    <v-icon >{{ list.icon }}</v-icon>
                  </v-list-item-icon>
                </template>
                {{ list.title }}
              </v-tooltip>
              <v-list-item-title> {{ list.title }} </v-list-item-title>
            </v-list-item>
          </div>
        </div>

      </v-list-item-group>
    </v-list>
    <span @dblclick="CopyToClipboard" v-if="mini" class="version-label">{{version}}({{getFormattedDate(date)}})</span>
  </v-navigation-drawer>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import * as getterTypes from "@/store/modules/auth/types/getters";
import lang from "@/mixins/language";
import user from "../../mixins/user";
import * as mutations from "@/store/modules/auth/types/mutations";
import * as actionTypes from "@/store/modules/auth/types/actions";
import navigationDraver from './navigationDrawer'
import authService from "@/services/request/auth/authService";
import notifications from '@/mixins/notifications';
import EventBus from "@/events/EventBus";

export default {
  name: "LeftMenu",
  data: () => ({
    fixed: false,
    mini: false,
    miniMob: false,
    usersSelector: false,
    depotsSelector: false,
    userSettings: false,
    docsSelector: false,
    listData: [],
    docsList: [],
    version: '',
    date: '',
  }),
  methods: {
    getFormattedDate(date) {
      const formattedDate = new Date(date);
      const day = formattedDate.getDate();
      const month = formattedDate.toLocaleString('en-gb', { month: 'short' });
      return `${day} ${month}`;
    },
    CopyToClipboard() {
      const data = `${this.version} (${this.getFormattedDate(this.date)})`;
      navigator.clipboard.writeText(data);
      this.setSuccessNotification(this.$t("profile.copied_to_clipboard"));
    },
    ...mapActions("auth", {
      fetchUserData: actionTypes.FETCH_USER_DATA
    }),
    navigateTo(routeName) {
      if (!routeName) return;
      this.$router.push({ name: routeName }).catch(() => {});
    },
    fetchData() {
      if (!this.$vuetify.breakpoint.mdAndUp) {
        this.mini = true;
        this.miniMob = this.menuPosition;
      } else {
        this.mini = this.menuPosition;
        this.miniMob = false;
      }
      //
      //
    }
  },
  async mounted() {
    await this.fetchData();
    EventBus.$on("balance-added-rerender", async item => {
      this.renderMenu();
    });
  },
  computed: {
    ...mapGetters("auth", {
      menuPosition: getterTypes.GET_MENU_POSITION
    }),
    ...mapGetters(["currentLanguage"]),
    iconColor() {
      return this.$vuetify.theme.dark ? "#FFFFFF" : "#757575";
    },
  },
  watch: {
    currentLanguage: {
      handler() {
        this.renderMenu()
      }
    },
    miniMob(e) {
      this.$store.commit(`auth/${mutations.SET_MENU_POSITION}`, e);
    },
    menuPosition: "fetchData"
  },
  mixins: [lang, user,navigationDraver, notifications]
};
</script>
<style lang="scss">
.version-label {
  position: absolute;
  bottom: 0px;
  left: 10px;
  font-size: 8px;
  color: #aeaeae;
}
.load-mini-label {
  position: absolute;
  bottom: 0px;
  right: 10px;
  font-size: 8px;
  color: #aeaeae;
}
.load-label {
  position: absolute;
  bottom: 0px;
  left: 10px;
  font-size: 8px;
  color: #aeaeae;
}
.v-list-item--active{
  // background-color: #30e90b38 !important;

}

.menu-label {
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  text-align: left;
  padding: 8px 0;
  margin-left: 20px;
  color: var(--Colours-grey, #4B5262);
}
</style>
