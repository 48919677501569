import user from "../../mixins/user";

export default {
  mounted() {
    this.renderMenu()
  },
  mixins: [user],
  methods: {
    renderMenu() {
      this.listData = [
        {
          permission: this.permissions.all_users,
          label: this.$t("sideBar.main"),
          isLabel: true
        },
        {
          permission: this.permissions.all_users,
          icon: "mdi-home",
          navigate_name: "panel",
          title: this.$t("sideBar.panel"),
        },
        {
          permission: this.permissions.all_users,
          icon: "mdi-newspaper-variant",
          navigate_name: "news",
          title: this.$t("sideBar.news"),
        },
        {
          permission: this.permissions.all_users,
          icon: "mdi-cart-outline",
          navigate_name: "ordersCatalog",
          title: this.$t("sideBar.orders"),
        },
        {
          permission: this.permissions.all_users,
          icon: "mdi-gavel",
          navigate_name: "offersCatalog",
          title: this.$t("sideBar.offersCatalog"),
        },
        {
          permission: this.permissions.can_see_streams,
          icon: "mdi-wifi",
          navigate_name: "streams",
          title: this.$t("sideBar.streams"),
        },
        {
          permission: this.permissions.all_users,
          icon: "mdi-chart-line",
          navigate_name: "statistic",
          title: this.$t("sideBar.statistic"),
        },
        {
          permission: this.permissions.all_users,
          icon: "mdi-cash-multiple",
          navigate_name: "transactions",
          title: this.$t("sideBar.transactions"),
        },
        {
          permission: this.permissions.can_see_admin_panel,
          label: this.$t("sideBar.additional"),
          isLabel: true
        },
        {
          permission: this.permissions.can_see_admin_panel,
          icon: "mdi-human-edit",
          navigate_name: "admin-panel",
          title: this.$t("sideBar.admin_panel"),
        },
        {
          permission: this.permissions.can_see_stat_trans_seller,
          icon: "mdi-chart-areaspline",
          navigate_name: "statistic-seller",
          title: this.$t("sideBar.statistic_seller"),
        },
        {
          permission: this.permissions.can_see_stat_trans_seller,
          icon: "mdi-cash-fast",
          navigate_name: "transactions-seller",
          title: this.$t("sideBar.transactions_seller"),
        },
      ];
    }
  }
};
