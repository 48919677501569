<template>
  <v-dialog
    v-model="visibility"
    max-width="420"
    :transition="$dialogTransition"
    persistent
  >
    <v-card min-height="200">
      <v-card-title
        class="headline"
        :class="{ 'px-0': $vuetify.breakpoint.smAndDown }"
      >
        <v-alert
          text
          dense
          type="error"
          class="w-100 v-custom-alert"
          :class="{ 'text-caption': $vuetify.breakpoint.xs }"
        >
          {{ $t("form.deleteImgDialog.Confirm") }}</v-alert
        >
      </v-card-title>
      <v-card-text>
        <span
          class="d-block"
          :class="{ 'text-caption': $vuetify.breakpoint.xs }"
        >
          {{ $t("form.deleteImgDialog.Textconfirm") }}
        </span>
      </v-card-text>
      <v-card-actions class="px-6"
        ><v-row>
          <v-col cols="6"
            ><v-btn
              color="primary"
              block
              dark
              text
              class="w-100 v-custom-tonal-btn--primary"
              @click="visibility = false"
              >{{ $t("btn.cancel") }}</v-btn
            ></v-col
          >
          <v-col cols="6"
            ><v-btn
              color="error"
              block
              @click="confirmDelete()"
              >{{ $t("btn.delete") }}</v-btn
            ></v-col
          >
        </v-row></v-card-actions
      >
    </v-card>
  </v-dialog>
</template>

<script>
import dialogMixin from "@/mixins/dialogMixin";
import notifications from "@/mixins/notifications";

export default {
  data: () => ({
    deleteQuery: "",
  }),
  mixins: [dialogMixin, notifications],
  methods: {
    confirmDelete() {
      this.visibility = false;
      this.$emit("delete");
    },
  },
};
</script>
