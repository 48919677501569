<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
          style="border:thin solid #e3e3e3;"
          v-bind="attrs"
          v-on="on"
          elevation="0"
          :ripple="false"
      >
                <span class="primary--text primary--darken-2">
                  <v-icon size="16">mdi-currency-uah</v-icon>: {{ balance }}
                </span>
      </v-btn>
    </template>
    <span> {{ $t("form.balance") }}: {{ balance }}</span>
  </v-tooltip>
</template>

<script>

import user from "@/mixins/user";
import {mapActions} from "vuex";
import * as actionTypes from "@/store/modules/auth/types/actions";

export default {
  name: "BalanceComponent",
  mixins: [user],
  data: () => ({
    balance: 0,
    intervalFetchUserData: "",
  }),
  async mounted() {
    this.balance = this.getUser.balance;
    this.getInfo();
  },
  methods: {
    ...mapActions("auth", {
      fetchData: actionTypes.FETCH_USER_DATA
    }),
    async getInfo() {
      try {
        const dayInMilliseconds = 60000;
        this.intervalFetchUserData = setInterval(async () => {
          this.fetchData();
        }, dayInMilliseconds);
      } catch (e) {
        console.log(e);
      }
    },
  },
  destroyed() {
    clearInterval(this.intervalFetchUserData);
  },
};
</script>
