<template>
  <div>
    <v-carousel hide-delimiters height="200px" v-model="carouselIndex">
      <v-carousel-item
          v-if="images.length"
          v-for="(img, index) in images"
          :key="index"
          :src="getImageSrc(img)"
          contain
      ></v-carousel-item>
      <v-carousel-item
          v-if="!images.length"
          src="@/assets/images/empty-product.png"
          contain
      ></v-carousel-item>
    </v-carousel>

    <v-row class="mt-3">
      <v-col
          v-for="(img, index) in images"
          :key="index"
          class="thumbnail-container ml-2 mb-2"
          style="max-width: 60px; min-width: 60px; height: 60px; padding: 0;"
      >
      <v-img
          :src="getImageSrc(img)"
          aspect-ratio="1"
          contain
          class="mb-2"
          :class="{ 'active-thumbnail': carouselIndex === index }"
          @click="carouselIndex = index"
          style="width: 100%; height: 100%;"
      ></v-img>

      <v-btn v-if="type === 'addEdit'" icon x-small class="delete-icon" @click="removeImage(index, img)">
        <v-icon color="red" x-small>mdi-close</v-icon>
      </v-btn>
      </v-col>
      <v-col
          class="d-flex justify-center align-center"
          style="max-width: 60px; min-width: 60px; height: 60px; padding: 0;"
      >

        <v-tooltip right>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" v-if="type === 'addEdit'" icon large @click="triggerFileInput">
              <v-icon color="primary" large>mdi-plus</v-icon>
            </v-btn>
          </template>
          {{ $t('home.add_photo') }}
        </v-tooltip>
      </v-col>
    </v-row>
    <input type="file" ref="fileInput" @change="addImage" multiple style="display: none;" />

    <deleteImgDialog
        :visible="showDeleteDialog"
        @close="showDeleteDialog = false"
        @delete="removeImage"
    />
  </div>
</template>

<script>
import DeleteImgDialog from "@/components/dialog/deleteImgDialog.vue";

export default {
  components: { DeleteImgDialog },
  data: () => ({
    carouselIndex: 0,
    showDeleteDialog: false,
    objectUrls: new Map()
  }),
  methods: {
    triggerFileInput() {
      this.$refs.fileInput.click();
    },
    addImage(event) {
      console.log(event);
      const files = event.target.files;
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        if (!file.type.startsWith('image/')) {
          alert('Only image files are allowed!');
          continue;
        }

        const maxSize = 5120 * 1024;
        if (file.size > maxSize) {
          alert('Image size must be less than 5MB!');
          continue;
        }

        this.images.push(file);
        const objectUrl = URL.createObjectURL(file);
        this.objectUrls.set(file, objectUrl);
      }
    },
    async convertImageUrlsToFiles() {
      for (let i = 0; i < this.images.length; i++) {
        const img = this.images[i];
        if (typeof img === 'object' && img.image) {
          try {
            const response = await fetch(img.image);
            const blob = await response.blob();
            const file = new File([blob], `image_${i}.jpg`, { type: blob.type });
            this.$set(this.images, i, file);
            const objectUrl = URL.createObjectURL(file);
            this.objectUrls.set(file, objectUrl);
          } catch (error) {
            console.error('Error converting image URL to File:', error);
          }
        }
      }
    },
    removeImage(index, img) {
      this.$emit('removeImage', img);
      if (this.images.length > 0) {
        const removedFile = this.images.splice(index, 1)[0];
        if (removedFile instanceof File) {
          const objectUrl = this.objectUrls.get(removedFile);
          URL.revokeObjectURL(objectUrl);
          this.objectUrls.delete(removedFile);
        }
        this.carouselIndex = Math.max(this.carouselIndex - 1, 0);
      }
    },
    getImageSrc(img) {
      return this.objectUrls.get(img) || img.image;
    }
  },
  props: {
    images: {
      type: Array,
      required: true
    },
    type: {
      type: String,
      required: true
    }
  },
  /*async mounted() {
    await this.convertImageUrlsToFiles();
  },*/
  beforeDestroy() {
    // Clean up object URLs to avoid memory leaks
    this.objectUrls.forEach((url) => {
      URL.revokeObjectURL(url);
    });
    this.objectUrls.clear();
  }
};
</script>

<style scoped>
.thumbnail-container {
  position: relative;
}

.delete-icon {
  position: absolute;
  top: 5px;
  right: 5px;
  background-color: white;
  z-index: 1;
}

.active-thumbnail {
  border: 2px solid #1EB564;
}
</style>