<template>
  <VDialog
    v-model="visibility"
    max-width="600px"
    content-class="cross__dialog"
    scrollable
    transition="slide-y-transition"
  >
    <VCard class="d-flex justify-center pa-3">
      <div class="cross__inner">
        <VBtn fab small elevation="0" @click="closeDialog">
          <VIcon>mdi-close</VIcon>
        </VBtn>
      </div>
      <img
        v-if="imgpath"
        :src="imgpath"
        v-show="isImgLoaded"
        @load="isImgLoaded = true"
        class="ma-auto imgDialog-img"
      />
      <template v-if="!isImgLoaded">
        <VRow class="fill-height ma-0" align="center" justify="center">
          <v-progress-circular
            indeterminate
            color="primary"
          ></v-progress-circular>
        </VRow>
      </template>
    </VCard>
  </VDialog>
</template>

<script>
import _ from "lodash";
import dialogMixin from "@/mixins/dialogMixin";

export default {
  name: "imgDialog",
  mixins: [dialogMixin],
  data: () => ({
    isImgLoaded: false,
  }),
  props: {
    imgpath: {
      require: true,
    },
  },
  methods: {
    closeDialog() {
      this.$emit("close", false);
    },
  },
};
</script>

<style scoped>
.imgDialog-img {
  width: 100% !important;
  height: 100% !important;
  margin-bottom: 0;
  padding-bottom: 0;
  object-fit: contain;
}
</style>
