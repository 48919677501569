<template>
  <div>
    <v-app-bar
      height="56"
      class="header__wrapper"
      :color="$vuetify.theme.dark ? 'grey darken-3' : 'grey lighten-5'"
    >
      <v-row align="center">
        <v-col cols="auto" class="px-0 py-0">
          <v-btn icon @click="toggleLeftMenu(!mini)" size="24"
          ><v-icon v-if="menuPosition == '1'" size="24">mdi-menu-open</v-icon>
            <v-icon v-if="menuPosition == '0'" size="24">mdi-menu-close</v-icon>
          </v-btn
          >
        </v-col>
        <v-col cols="auto" class="d-flex align-center">
          <div @click="$router.push('/').catch(() => {})">
            <span>
              {{'GoDrop'}}
            </span>
          </div>
        </v-col>
<!--        <v-col cols="auto" class="d-flex align-center ml-2" style="margin-right: 85px">
          <div>
            <span>
              {{ $t("form.workloadCc") }}
            </span>
            <v-progress-linear
                rounded
                height="7"
                color="#1B59F8"
                model-value="20"
            >
            </v-progress-linear>
          </div>
        </v-col>-->
        <v-spacer></v-spacer>
        <v-col cols="auto" class="pl-0"> <BalanceComponent /> </v-col>
        <v-col cols="auto" class="pl-0"> <Theme /> </v-col>
        <v-col cols="auto" class="px-0 py-0"> <Language /> </v-col>
<!--        <v-col cols="auto" class="pl-0">
          <img
            :src="require(`@/assets/images/purse.svg`)"
            alt="Purse image"
            draggable="false"
            class="showimg"
        />
        </v-col>
        <v-col cols="auto" class="px-4 py-0">
          <img
              :src="require(`@/assets/images/money-exchange.svg`)"
              alt="Money exchange image"
              draggable="false"
              class="showimg"
          />
        </v-col>-->
        <v-col cols="auto" class="d-flex align-center">
          <UserDropdown />
        </v-col>
      </v-row>
    </v-app-bar>
    <navigationDrawer />
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import * as getterTypes from "@/store/modules/auth/types/getters";
import * as mutationTypes from "@/store/modules/auth/types/mutations";
import UserDropdown from "@/components/layout/UserDropdown.vue";
import NavigationDrawer from "@/components/layout/navigationDrawer.vue";
import Theme from "@/components/layout/Theme.vue";
import Language from "@/components/layout/Language.vue";
import * as actionTypes from "@/store/modules/auth/types/actions";
import user from "@/mixins/user";
import BalanceComponent from "@/components/layout/Balance.vue";

export default {
  name: "HeaderComponent",
  components: {
    BalanceComponent,
    NavigationDrawer, UserDropdown, Theme, Language
  },
  mixins:[user],
  data: () => ({
    tasksAmount: 0,
  }),
  methods: {
    toggleLeftMenu(param) {
      this.$store.commit(`auth/${mutationTypes.SET_MENU_POSITION}`, param);
    },
  },
  computed: {
    ...mapGetters("auth", {
      menuPosition: getterTypes.GET_MENU_POSITION
    }),

    mini: {
      get() {
        return this.menuPosition;
      },
      set(mini) {
        return mini;
      },
    },
  },
};
</script>

<style scoped>
.header__wrapper {
  position: relative;
  width: 100%;
}
</style>
