// eslint-disable-next-line import/prefer-default-export
export const userNormalizer = (User) => ({
  name: User.name,
  email: User.email,
  image: User.image,
  phone_number: User.phone_number,
  id: User.id,
  role: User.role,
  balance: User.balance,
});
