import * as getterTypes from "./types/getters";

export default {
  [getterTypes.GET_MENU_POSITION]: (state) => state.menuPosition,
  [getterTypes.GET_SHOW_500_ERROR_DIALOG]: (state) => state.show500ErrorDialog,
  [getterTypes.GET_USER_DATA]: (state) => state.user,
  [getterTypes.GET_PERMISSIONS]: (state) => {
    const isAdmin = () => state?.user?.role == 'admin';
    const isSeller = () => state?.user?.role == 'seller';
    const isArbitrageur = () => state?.user?.role == 'arbitrageur';
    const isModerator = () => state?.user?.role == 'moderator';
    const all = () => isAdmin() || isSeller() || isArbitrageur() || isModerator();
    return {
      all_users: all(),
      is_admin: isAdmin(),
      is_seller: isSeller(),
      is_arbitrageur: isArbitrageur(),
      is_moderator: isModerator(),
      can_see_admin_panel: isAdmin() || isModerator(),
      can_see_streams: all(),
      can_add_edit_article: isAdmin() || isModerator(),
      can_edit_offer: isAdmin() || isModerator(),
      can_add_offer: isAdmin() || isModerator() || isSeller(),
      can_add_edit_stream: isAdmin() || isModerator(),
      can_add_edit_geo_users: isAdmin() ,
      can_add_edit_category: isAdmin() || isModerator(),
      can_see_stat_trans_seller: isAdmin() || isModerator() || isSeller(),
      can_see_integrations: isAdmin() || isModerator() || isSeller(),
      pages: {
        panel: all(),
        offersCatalog: all(),
        ordersCatalog: all(),
        "product-details": all(),
        "article-details": all(),
        streams: all(),
        statistic: all(),
        transactions: all(),
        "statistic-seller": isAdmin() || isModerator() || isSeller(),
        "transactions-seller": isAdmin() || isModerator() || isSeller(),
        "admin-panel": isAdmin() || isModerator(),
        news: all(),
        userSettings: all(),
      }
    };
  },
};
