import * as mutationTypes from "./types/mutations";
import { DEFAULT_STORAGE } from "@/mixins/constants";

export default {
  [mutationTypes.SET_IS_DARK]: (state, value) => {
    let data = DEFAULT_STORAGE;
    if (localStorage.getItem("drop-data") !== null) {
      data = JSON.parse(localStorage.getItem("drop-data"));
    }
    data.ui.isDark = value;
    localStorage.setItem("drop-data", JSON.stringify(data));
    state.isDark = value;
  },
  [mutationTypes.SET_CHOSEN_THEME]: (state, value) => {
    let data = DEFAULT_STORAGE;
    if (localStorage.getItem("drop-data") !== null) {
      data = JSON.parse(localStorage.getItem("drop-data"));
    }
    data.ui.theme = value;
    localStorage.setItem("drop-data", JSON.stringify(data));

    state.chosenTheme = value;
  }
};
