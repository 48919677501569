<template>
  <div>
    <div>
      <HeaderComponent />
    </div>
    <div
      class="content-block"
      style="min-height: 90vh"
      :style="menuPosition ? 'padding-left: 237.5px;' : 'padding-left: 0px;'"
    >
      <div class="content-block_wrap">
        <slot></slot>
        <div class="scroll-up__wrapper">
          <v-fab-transition>
            <VBtn
              v-if="isShowUpBtn"
              color="primary"
              small
              absolute
              bottom
              right
              fab
            >
              <VIcon>mdi-chevron-up</VIcon>
            </VBtn>
          </v-fab-transition>
        </div>
        <Loader />
      </div>
    </div>
  </div>
</template>

<script>
import HeaderComponent from "@/components/layout/HeaderComponent.vue";
import NavigationDrawer from "@/components/layout/navigationDrawer.vue";
import {mapActions, mapGetters} from "vuex";
import * as getterTypes from "@/store/modules/auth/types/getters";
import theme from "@/mixins/theme";
import localStorageMixin from "@/mixins/localStorage";
import Loader from "@/components/layout/Loader.vue";
import loader from "@/mixins/loader";
import * as actionTypes from "@/store/modules/auth/types/actions";

export default {
  name: "AppLayout",
  mixins: [theme, localStorageMixin, loader],
  components: {
    NavigationDrawer,
    HeaderComponent,
    Loader,
  },
  data: () => ({
    isShowUpBtn: false,
    online: true,
    showOnlineBackLine: false,
  }),
  async mounted() {
    this.online = this.isOnline;
    try {
      this.onChangeSelectedTheme(this.getStorageItem("theme"));
      // eslint-disable-next-line no-empty
    } catch (e) {};
    this.fetchUserData();
    this.fetchUser();
  },
  methods: {
    ...mapActions("auth", {
      fetchUser: actionTypes.FETCH_USER_DATA
    }),
    async fetchUserData() {
      try {
        const dayInMilliseconds = 60000;
        this.intervalFetchUserData = setInterval(() => {
          this.fetchUser();
        }, dayInMilliseconds);
      } catch (e) {
        console.log(e);
      }
    },
  },
  watch: {
    isOnline: {
      deep: true,
      handler() {
        if (this.isOnline && this.online === false) {
          this.showOnlineBackLine = true;
          document.location.reload();
        } else {
          this.online = this.isOnline;
        }
      },
    },
  },
  computed: {
    ...mapGetters(["currentLanguage"]),
    ...mapGetters("auth", {
      menuPosition: getterTypes.GET_MENU_POSITION,
    }),
  },
};
</script>

<style lang="scss">
.content-block {
  display: block;
  transition: all 0.2s;
}
.content-block_wrap {
  scroll-behavior: smooth;
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
  height: calc(100vh - 56px);
  max-height: 100%;
  padding: 4px 8px;
}
.listGroupMenu {
  .v-list-group__items {
    padding-left: 18px;
    border-left: 3px solid #ff7a00;
  }
}

.v-navigation-drawer--mini-variant {
  .listGroupMenu {
    .v-list-group__items {
      padding-left: 0;
      border-left: 3px solid;
      margin-left: -3px;
    }
  }
}

.active-orange--text {
  color: var(--v-orange-base) !important;

  svg {
    fill: var(--v-orange-base) !important;
  }
}
</style>
