<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <path d="M1.48749 0.456249C1.21093 0.545312 0.878119 0.784374 0.690619 1.0375C0.381244 1.45937 0.385931 1.38906 0.399994 4.89531L0.414056 8.05469L0.545306 8.32187C0.709369 8.65469 1.06093 8.97812 1.40312 9.10469C1.65156 9.19844 1.74531 9.20312 4.79687 9.20312C7.89999 9.20312 7.93749 9.20312 8.20468 9.1C8.57499 8.96406 8.96406 8.575 9.09999 8.20469C9.20312 7.9375 9.20312 7.9 9.20312 4.79688C9.20312 1.74531 9.19843 1.65156 9.10468 1.40312C8.97812 1.06094 8.65468 0.709374 8.32187 0.545312L8.05468 0.414062L4.86718 0.404687C2.36406 0.395312 1.63749 0.409374 1.48749 0.456249ZM7.56249 4.79688V7.5625H4.79687H2.03124V4.79688V2.03125H4.79687H7.56249V4.79688Z" fill="#4B5262"/>
    <path d="M10.7969 1.21094C10.7969 1.66398 11.1641 2.03125 11.6172 2.03125H12.8125H14.0078C14.4609 2.03125 14.8281 1.66398 14.8281 1.21094C14.8281 0.757891 14.4609 0.390625 14.0078 0.390625H12.8125H11.6172C11.1641 0.390625 10.7969 0.757891 10.7969 1.21094Z" fill="#4B5262"/>
    <path d="M10.7969 4.39844C10.7969 4.85148 11.1641 5.21875 11.6172 5.21875H15.2031H18.7891C19.2421 5.21875 19.6094 4.85148 19.6094 4.39844C19.6094 3.94539 19.2421 3.57812 18.7891 3.57812H15.2031H11.6172C11.1641 3.57812 10.7969 3.94539 10.7969 4.39844Z" fill="#4B5262"/>
    <path d="M10.7969 7.58594C10.7969 8.03898 11.1641 8.40625 11.6172 8.40625H13.6094H15.6016C16.0546 8.40625 16.4219 8.03898 16.4219 7.58594C16.4219 7.13289 16.0546 6.76562 15.6016 6.76562H13.6094H11.6172C11.1641 6.76562 10.7969 7.13289 10.7969 7.58594Z" fill="#4B5262"/>
    <path d="M1.38906 10.8953C1.06094 11.0219 0.704688 11.35 0.545313 11.6781L0.414062 11.9453V15.2031V18.4609L0.53125 18.6953C0.676563 18.9953 1.00469 19.3234 1.30469 19.4688L1.53906 19.5859H4.79688H8.05469L8.32188 19.4547C8.65469 19.2906 8.97813 18.9391 9.10469 18.5969C9.19844 18.3484 9.20312 18.2547 9.20312 15.2031C9.20312 12.1 9.20312 12.0625 9.1 11.7953C8.96406 11.425 8.575 11.0359 8.20469 10.9C7.9375 10.7969 7.9 10.7969 4.78281 10.8016C1.75 10.8016 1.62344 10.8063 1.38906 10.8953ZM7.5625 15.2031V17.9688H4.79688H2.03125V15.2031V12.4375H4.79688H7.5625V15.2031Z" fill="#4B5262"/>
    <path d="M10.7969 11.6172C10.7969 12.0702 11.1641 12.4375 11.6172 12.4375H15.2031H18.7891C19.2421 12.4375 19.6094 12.0702 19.6094 11.6172C19.6094 11.1641 19.2421 10.7969 18.7891 10.7969H15.2031H11.6172C11.1641 10.7969 10.7969 11.1641 10.7969 11.6172Z" fill="#4B5262"/>
    <path d="M10.7969 14.8047C10.7969 15.2577 11.1641 15.625 11.6172 15.625H12.8125H14.0078C14.4609 15.625 14.8281 15.2577 14.8281 14.8047C14.8281 14.3516 14.4609 13.9844 14.0078 13.9844H12.8125H11.6172C11.1641 13.9844 10.7969 14.3516 10.7969 14.8047Z" fill="#4B5262"/>
    <path d="M10.7969 17.9922C10.7969 18.4452 11.1641 18.8125 11.6172 18.8125H13.6094H15.6016C16.0546 18.8125 16.4219 18.4452 16.4219 17.9922C16.4219 17.5391 16.0546 17.1719 15.6016 17.1719H13.6094H11.6172C11.1641 17.1719 10.7969 17.5391 10.7969 17.9922Z" fill="#4B5262"/>
  </svg>
</template>

<script>
import iconMixin from './iconMixin';

export default {
  mixins: [iconMixin],
  name: 'Catalog',
};
</script>

<style scoped>

</style>
