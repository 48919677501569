const dropData = JSON.parse(localStorage.getItem('drop-data'));

export default {
    utmOrderList: dropData && dropData.utmOrderList ? dropData.utmOrderList : [
        { id: 0, label: 'utm_source' },
        { id: 1, label: 'utm_medium' },
        { id: 2, label: 'utm_campaign' },
        { id: 3, label: 'utm_content' },
        { id: 4, label: 'utm_term' }
    ]
  };
