import Vue from 'vue'
import VueI18nManager from 'vue-i18n-manager'
import store from '@/store'
import router from '@/router'
import locales from '@/locales'

Vue.use(VueI18nManager, {
  store,
  router,
  config: {
    defaultCode: 'uk-UK',
    languages: [
      {
        name: 'Ukrainian',
        title: 'UK',
        key: 'uk',
        code: 'uk-UK',
        lang:'uk_UA',
        urlPrefix: 'uk',
        translationKey: 'uk',
        flagCode:"UA",
      },
      {
        name: 'English',
        title: 'EN',
        key: 'en',
        code: 'en-EN',
        urlPrefix: 'en',
        lang:'en_EN',
        translationKey: 'en',
        flagCode:"GB",
      },
      {
        name: 'Russian',
        title: 'RU',
        key: 'ru',
        code: 'ru-RU',
        lang: 'ru_RU',
        urlPrefix: 'ru',
        translationKey: 'ru',
      }
    ],
    translations: locales
  }
})
