import Vue from "vue";
import Vuex from "vuex";
// eslint-disable-next-line import/no-cycle
import auth from "@/store/modules/auth";
import theme from "@/store/modules/theme";
import language from "@/store/modules/language";
import userKey from "@/store/modules/usersKey";
import notifications from "@/store/modules/notifications";
import loader from "@/store/modules/loader";
import offersCatalogListValue from "@/store/modules/offersCatalogListValue";
import utmOrderValues from "@/store/modules/utmOrderValues";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    theme,
    language,
    userKey,
    notifications,
    loader,
    offersCatalogListValue,
    utmOrderValues
  },
});
