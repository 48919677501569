<template>
  <v-btn
    text
    :icon="$vuetify.breakpoint.mobile"
    class="px-md-2 mx-1"
    :small="$vuetify.breakpoint.mobile"
    :outlined="isOutlined"
    @click="onClickTheme()"
  >
    <v-icon :dense="$vuetify.breakpoint.mobile">mdi-theme-light-dark</v-icon>
  </v-btn>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import * as actionTypes from "@/store/modules/theme/types/actions";
import theme from "@/mixins/theme";
import { THEMES } from "@/mixins/constants";

export default {
  mixins: [theme],
  name: "ThemeComponent",
  props: {
    isOutlined: {
      require: false,
      default: true,
    },
  },
  methods: {
    ...mapActions("theme", {
      setIsDark: actionTypes.SET_IS_DARK,
      setChosenTheme: actionTypes.SET_CHOSEN_THEME,
    }),
    onClickTheme() {
      this.$vuetify.theme.isDark = !this.$vuetify.theme.isDark;
      const isDark = this.$vuetify.theme.isDark;
      this.onChangeSelectedTheme(isDark ? THEMES.DARK : THEMES.LIGHT);
      this.setIsDark(isDark);
    },
  },
};
</script>
