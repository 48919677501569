// eslint-disable-next-line import/no-cycle
import requestService from "../requestService";

const prefix = '/transactions';

export default {
  async getTransactions(params) {
    const response = await requestService.get(`${prefix}`,
        {...params}
        );
    return response?.data?.data;
  },
    async getTransactionsSeller(params) {
    const response = await requestService.get(`${prefix}/seller`,
        {...params}
        );
    return response?.data?.data;
  },
    async getTransactionsReportSeller(params) {
        const response = await requestService.get(`${prefix}/seller/report`,
            {...params}
        );
        return response?.data?.data;
    },
    async getTransactionsReportArbitrageur(params) {
        const response = await requestService.get(`${prefix}/report`,
            {...params}
        );
        return response?.data?.data;
    },
    async getInfo(params) {
    const response = await requestService.get(`/check_info`,
        {...params}
        );
    return response?.data?.data;
  },
};
