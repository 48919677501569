<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
    <path d="M7.54674 0.0510254C6.83085 0.191647 6.1533 0.5709 5.62065 1.12913C5.16469 1.61065 4.89197 2.09644 4.70873 2.78676C4.63203 3.06375 4.62777 3.37482 4.62777 9.40453V15.7325L4.74708 15.9328C4.91753 16.2268 5.18173 16.3717 5.54394 16.3717C5.84649 16.3675 6.00416 16.3078 6.20018 16.1118C6.48142 15.8263 6.45586 16.376 6.48142 9.46845L6.50273 3.20437L6.63483 2.92313C6.86068 2.45012 7.21862 2.12201 7.70867 1.93451L7.95156 1.84076L12.5751 1.82798C15.6389 1.81945 17.3136 1.83224 17.5394 1.85781C18.396 1.97286 19.0266 2.59501 19.1374 3.43874C19.1928 3.86913 19.1928 16.1331 19.1374 16.5635C19.0309 17.3859 18.4002 18.0208 17.5821 18.1402C17.1772 18.1955 4.81952 18.1955 4.4147 18.1402C3.59654 18.0208 2.96587 17.3859 2.85934 16.5635C2.83803 16.3717 2.81672 14.0067 2.81672 10.7127C2.81672 5.36058 2.81246 5.17734 2.73576 5.02394C2.55679 4.67877 2.28833 4.53389 1.85794 4.5552C1.4659 4.57224 1.24857 4.7086 1.09091 5.03246L0.984375 5.25405L0.997159 11.1559C1.00994 17.795 0.980114 17.1899 1.34658 17.9569C1.71305 18.7112 2.29259 19.2907 3.04683 19.6529C3.82239 20.0236 3.11075 19.9895 10.862 20.0066C17.5352 20.0151 17.744 20.0151 18.1403 19.9341C19.2184 19.7126 20.1644 18.9668 20.6459 17.9569C21.0209 17.1771 20.9911 17.8717 20.9911 10.0011C20.9911 2.13053 21.0209 2.82512 20.6459 2.0453C20.1644 1.03964 19.2184 0.289656 18.1488 0.0723305C17.7695 -0.00863266 17.5267 -0.0128956 12.7881 -0.00863266C8.90183 -0.00437164 7.76407 0.00841141 7.54674 0.0510254Z" fill="#4B5262"/>
    <path d="M10.6575 5.48843C10.0566 5.72707 9.87338 6.57506 10.3293 7.00545C10.6234 7.28669 10.5254 7.27817 12.8648 7.26539L14.9912 7.2526L15.1659 7.12903C15.7412 6.73273 15.6645 5.81655 15.0253 5.52252C14.8676 5.45008 14.7142 5.44156 12.8094 5.44582C11.5694 5.44582 10.7214 5.46287 10.6575 5.48843Z" fill="#4B5262"/>
    <path d="M8.84657 9.1318C8.48863 9.2639 8.2713 9.59202 8.2713 10.0011C8.2713 10.2909 8.33948 10.4613 8.53976 10.6701C8.80396 10.9514 8.65908 10.9428 12.899 10.9301L16.7299 10.9173L16.9302 10.798C17.2242 10.6275 17.3691 10.3633 17.3691 10.0011C17.3691 9.63889 17.2242 9.37469 16.9302 9.20424L16.7299 9.08493L12.8735 9.0764C9.57951 9.06788 8.99146 9.0764 8.84657 9.1318Z" fill="#4B5262"/>
    <path d="M8.76117 12.8008C8.40323 12.9883 8.25408 13.2567 8.27965 13.6871C8.29669 14.0834 8.42453 14.2879 8.74413 14.4499L8.96998 14.5607H10.9983H13.0267L13.244 14.4541C13.5636 14.2922 13.7 14.0749 13.717 13.6871C13.7383 13.2567 13.5935 12.9883 13.2483 12.8093C13.0992 12.7326 12.9713 12.7283 10.9898 12.7283C9.15747 12.7326 8.87197 12.7411 8.76117 12.8008Z" fill="#4B5262"/>
    <path d="M16.0482 12.8008C15.4048 13.1375 15.3451 13.994 15.9332 14.4116C16.1036 14.5266 16.1505 14.5394 16.453 14.5394C16.7556 14.5394 16.8025 14.5266 16.9729 14.4116C17.5482 14.0025 17.497 13.1289 16.8749 12.8136C16.6704 12.7071 16.2357 12.7028 16.0482 12.8008Z" fill="#4B5262"/>
  </svg>
</template>

<script>
import iconMixin from './iconMixin';

export default {
  mixins: [iconMixin],
  name: 'Newspaper',
};
</script>

<style scoped>

</style>
