import Vue from "vue";
import App from "./App.vue";
import vuetify from "@/plugins/vuetify";
import "./assets/styles/common.scss";
import store from "./store";
import "./utils/translations";
import router from "@/router";
import VueOffline from "vue-offline";
import loaderMixin from "@/mixins/loader";
import VObserver from "@/directives/VObserver";
import avatarComponent from "@/components/UI/t-avatar.vue";
import Newspaper from "@/components/icons/Newspaper.vue";
import HomePanel from "@/components/icons/HomePanel.vue";
import Catalog from "@/components/icons/Catalog.vue";
import Cart from "@/components/icons/Cart.vue";
import imgComponent from "@/components/UI/t-img.vue";
import photoCarousel from "@/components/UI/photoCarousel.vue";
import draggable from "vuedraggable";


Vue.use(VueOffline);

Vue.config.productionTip = false;

Vue.prototype.$dialogTransition = "slide-y-transition";

Vue.prototype.$getChangedData = function (modyData, copyData) {
  const copyDataKeys = Object.keys(copyData);
  const res = [];
  copyDataKeys.forEach((key) => {
    if (copyData[key] !== modyData[key]) {
      res.push({ field: key, value: modyData[key] });
    }
  });
  return res;
};


// Icons
Vue.component("cart-icon", Cart);
Vue.component("catalog-icon", Catalog);
Vue.component("home-panel-icon", HomePanel);
Vue.component("newspaper-icon", Newspaper);

// Components
Vue.component("t-avatar", avatarComponent);
Vue.component("t-img", imgComponent);
Vue.component("p-carousel", photoCarousel);
Vue.component("draggable", draggable);

// Directives
Vue.directive("observer", VObserver);

// Mixins
Vue.mixin(loaderMixin);

const app = new Vue({
  store,
  vuetify,
  router,
  render: (h) => h(App),
});

Vue.initI18nManager().then(() => {
  app.$mount("#app");
});
