<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
    <path d="M0.644775 1.02881C0.390881 1.12235 0.266161 1.21589 0.128078 1.42078C0.0211753 1.58114 0.0078125 1.64795 0.0078125 1.95084C0.0078125 2.26264 0.0211753 2.31164 0.141441 2.48981C0.368609 2.80607 0.582415 2.89515 1.21047 2.92633C1.77171 2.95751 1.84743 2.97533 2.04787 3.12232C2.24832 3.26931 2.31958 3.42966 2.58684 4.28489C2.79174 4.94412 2.98327 5.8038 3.66032 9.12669C4.11466 11.3494 4.52891 13.2825 4.5779 13.4251C4.81844 14.0665 5.33513 14.6366 5.94982 14.944C6.62687 15.2825 6.3418 15.2691 12.0121 15.2558L17.0677 15.2424L17.3572 15.1444C18.1857 14.8638 18.8628 14.2179 19.1612 13.4251C19.2993 13.0509 20.2971 7.14453 20.2971 6.68129C20.2971 6.27595 20.1634 5.76371 19.9541 5.36282C19.718 4.90849 19.179 4.37843 18.7381 4.16017C18.0432 3.81719 18.6891 3.84391 11.2059 3.83055L4.49773 3.81719L4.35074 3.33612C4.13248 2.61899 3.90085 2.20919 3.50442 1.80831C3.14808 1.44751 2.73829 1.20698 2.25722 1.07335C1.90534 0.975356 0.85858 0.944176 0.644775 1.02881ZM17.9007 5.93297C18.0254 6.02206 18.1367 6.15123 18.2169 6.31158C18.3283 6.52984 18.3416 6.58775 18.3149 6.82383C18.2481 7.45188 17.3572 12.6901 17.2949 12.8059C17.1924 13.0019 16.8895 13.2514 16.7025 13.2914C16.6089 13.3093 14.4085 13.3271 11.8117 13.3271C8.08342 13.3271 7.05448 13.3137 6.92976 13.2692C6.74268 13.2024 6.47543 12.9529 6.3997 12.7703C6.37298 12.7035 6.04336 11.0999 5.6692 9.21132L4.99215 5.77707L11.3484 5.78598L17.7047 5.79934L17.9007 5.93297Z" fill="#4B5262"/>
    <path d="M7.00116 16.2758C6.12812 16.5876 5.64261 17.3181 5.70051 18.2356C5.75396 19.122 6.4132 19.8481 7.30405 19.9951C8.137 20.1332 8.97886 19.6788 9.34857 18.8949C9.45102 18.6677 9.48665 18.5207 9.50447 18.2356C9.55792 17.4205 9.17485 16.7435 8.46217 16.3916C8.14591 16.2357 8.08801 16.2223 7.67376 16.2089C7.33078 16.2 7.17043 16.2179 7.00116 16.2758Z" fill="#4B5262"/>
    <path d="M15.5533 16.2758C14.6803 16.5876 14.1948 17.3181 14.2527 18.2356C14.3551 19.8793 16.3997 20.6276 17.531 19.4294C17.8607 19.082 18.0299 18.6989 18.0567 18.2356C18.1101 17.4205 17.727 16.7435 17.0144 16.3916C16.6981 16.2357 16.6402 16.2223 16.2259 16.2089C15.883 16.2 15.7226 16.2178 15.5533 16.2758Z" fill="#4B5262"/>
  </svg>
</template>

<script>
import iconMixin from './iconMixin';

export default {
  mixins: [iconMixin],
  name: 'Cart',
};
</script>

<style scoped>

</style>
