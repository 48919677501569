import Vue from "vue";
import VueRouter from "vue-router";

// Guard
// eslint-disable-next-line import/no-cycle
import store from "@/store";
import * as actionTypes from "@/store/modules/auth/types/actions";
import LoginGuard from "../components/guard/LoginGuard.vue";
// eslint-disable-next-line import/no-cycle
import authService from "../services/request/auth/authService";

Vue.use(VueRouter);

const isAuthenticated = async (to, from, next) => {
  if (authService.getToken()) {
    return next();
  } else {
    next({ name: "login" });
  }
};

const isNotAuthenticated = (to, from, next) => {
  if (!authService.getToken()) {
    next();
    return;
  }
  next({ name: "home" });
};

const routes = [
  {
    path: "/:lang?/register",
    name: "register",
    beforeEnter: isNotAuthenticated,
    component: () => import("@/views/Register.vue"),
    meta: { title: 'GoDrop - Реєстрація' }
  },
  {
    path: "/:lang?/login",
    name: "login",
    beforeEnter: isNotAuthenticated,
    component: () => import("@/views/Login.vue"),
    meta: { title: 'GoDrop - Авторизація' }
  },
  {
    path: "",
    component: LoginGuard,
    beforeEnter:isAuthenticated,
    children: [
      {
        path: "/:lang?/",
        name: "panel",
        component: () => import("@/views/PanelView.vue"),
      },
      {
        path: "/:lang?/news",
        name: "news",
        component: () => import("@/views/NewsView.vue")
      },
      {
        path: "/:lang?/news/article/:article",
        name: "article-details",
        component: () => import("@/components/news/articleDetailsPage.vue")
      },
      {
        path: "/:lang?/orders",
        name: "ordersCatalog",
        component: () => import("@/views/OrdersView.vue")
      },
      {
        path: "/:lang?/offersCatalog",
        name: "offersCatalog",
        component: () => import("@/views/OffersCatalogView.vue"),
      },
      {
        path: "/:lang?/offersCatalog/product/:product",
        name: "product-details",
        component: () => import("@/components/offersCatalog/offersCatalogInfo.vue")
      },
      {
        path: "/:lang?/streams",
        name: "streams",
        component: () => import("@/views/StreamsView.vue")
      },
      {
        path: "/:lang?/statistic",
        name: "statistic",
        component: () => import("@/views/StatisticView.vue")
      },
      {
        path: "/:lang?/transactions",
        name: "transactions",
        component: () => import("@/views/TransactionsView.vue")
      },
      {
        path: "/:lang?/statistic-seller",
        name: "statistic-seller",
        component: () => import("@/views/StatisticSellerView.vue")
      },
      {
        path: "/:lang?/transactions-seller",
        name: "transactions-seller",
        component: () => import("@/views/TransactionsSellerView.vue")
      },
      {
        path: "/:lang?/admin-panel",
        name: "admin-panel",
        component: () => import("@/views/AdminPanelView.vue")
      },
      {
        path: "/:lang?/settings",
        name: "userSettings",
        component: () => import("@/views/UserSettingsView.vue")
      },
    ]
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  if (!to.matched.length) {
    return next({ name: "news" });
  } else {
    if(authService.getToken()){
      const permissionsPage = store.getters["auth/getPermissions"].pages;

      if(permissionsPage[to.name]){
        next()
      } else {
        const pages = Object.keys(permissionsPage);
        const allowedPages = pages.filter(page => permissionsPage[page]);
        next({ name: allowedPages[0] })
      }
    } else {
      next()
    }
  }
});

router.afterEach((to, from) => {
  Vue.nextTick(() => {
    document.title = to.meta.title || 'GoDrop';
  });
});

export default router;
