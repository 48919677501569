// eslint-disable-next-line import/no-cycle
import authService from '@/services/request/auth/authService';
import * as actionTypes from './types/actions';
import * as mutationTypes from './types/mutations';
import transactionsService from "@/services/request/transactions/transactionsService";


export default {
    [actionTypes.LOG_IN]: async ({ commit }, payload) => {
         const response = await authService.logIn(payload);
         commit(mutationTypes.SET_USER_DATA, response);
    },
    [actionTypes.USER_UPDATE_DATA]: async ({ commit }, payload) => {
        commit(mutationTypes.SET_USER_DATA, payload);
    },
    [actionTypes.REGISTER]: async (context, payload) => {
        await authService.register(payload);
    },
    [actionTypes.LOG_OUT]: async ({ commit }) => {
        await authService.logOut();
        commit(mutationTypes.LOG_OUT);
    },
    [actionTypes.FETCH_USER_DATA]: async ({ commit }) => {
        const response = await transactionsService.getInfo();
        commit(mutationTypes.SET_USER_DATA, response);
    },
    [actionTypes.SHOW_500_ERROR_DIALOG]: ({ commit },status) => {
        commit(mutationTypes.SET_SHOW_500_ERROR_DIALOG,status);
    },
};
